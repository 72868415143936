<template>

    <div @click="changeLocale()">
        <div class="c-avatar" style="width: 33px;height: 33px;">
            <img alt="Thai" src="/img/languages/th_icon.png" class="c-avatar-img " v-if="$i18n.locale === 'th'" />
            <img alt="Eng" src="/img/languages/en_icon.png" class="c-avatar-img " v-else />
        </div>
        <h5 v-if="showName" class="mt-3 text-dark">{{ getLanguage() }}</h5>
    </div>
    <!-- <CDropdown :show.sync="showDropdown">
        <template #toggler>
            <CHeaderNavLink>
                <div class="c-avatar" style="width: 33px;height: 33px;">
                    <img alt="Thai" src="/img/languages/th_icon.png" class="c-avatar-img "
                        v-if="$i18n.locale === 'th'" />
                    <img alt="Eng" src="/img/languages/en_icon.png" class="c-avatar-img " v-else />
                </div>
                <h5 v-if="showName" class="mt-3 text-dark">{{ getLanguage() }}</h5>
            </CHeaderNavLink>
        </template>
<label>
    <CDropdownItem v-if="$i18n.locale === 'en'" @click="changeLocale('th')">
        <div class="c-avatar" style="width: 30px;height: 30px;">
            <img alt="Thai" src="/img/languages/th_icon.png" class="c-avatar-img " @click="changeLocale('th')" />
        </div>
        <span style="margin-left: 20px;"> ภาษาไทย </span>
    </CDropdownItem>
</label>
<label>
    <CDropdownItem v-if="$i18n.locale === 'th'" @click="changeLocale('en')">
        <div class="c-avatar" style="width: 30px;height: 30px;">
            <img alt="Eng" src="/img/languages/en_icon.png" class="c-avatar-img " @click="changeLocale('en')" />
        </div>
        <span style="margin-left: 20px;"> English </span>
    </CDropdownItem>
</label>
</CDropdown> -->
</template>
<script>
export default {
    props: {
        showName: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showDropdown: false
        }
    },
    methods: {
        trackButtonClick(buttonLabel) {
            if (typeof window.gtag === 'function') {
                window.gtag('event', 'click', {
                    event_label: buttonLabel,
                });
            } else {
                console.error('gtag is not defined.');
            }
        },
        changeLocale() {
            this.$i18n.locale = this.$i18n.locale === 'th' ? 'en' : 'th'
            this.trackButtonClick('home/change_lang_to_' + this.$i18n.locale)
        },
        getLanguage() {
            switch (this.$i18n.locale) {
                case 'en':
                    return 'English'
                case 'th':
                    return 'ภาษาไทย'


            }
        }
    },
}
</script>
